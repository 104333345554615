import React from 'react';
import { Tweet } from 'react-twitter-widgets';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ButtonLink from '../components/ButtonLink';
import SectionTitle from '../components/SectionTitle';
import NewsletterSubscribeForm from '../components/NewsletterSubscribeForm';
import FeaturedInLogosLinks from '../components/FeaturedInLogosLinks';
import ResponsiveIframe from '../components/ResponsiveIframe';
import SocialIcons from '../components/SocialIcons';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

function IndexPage({ data }) {
  const { body } = data.allPrismic.nodes[0].data;

  const getSectionTitle = (section) => section.primary.title[0].text;

  // Content Sections
  const socialIconsSection = body[1];
  const linksSection = body[2];
  const mostRecentVideoSection = body[3];
  const newsletterSection = body[4];
  const pressSection = body[5];

  return (
    <Layout>
      <SEO title="Andres en Ingles" />

      {/* Newsletter Subscription */}
      <section className="py-6" style={{ borderBottom: '1px solid rgba(49,49,53,0.1)' }}>
        <SectionTitle text={getSectionTitle(newsletterSection)} />
        <p>{newsletterSection.primary.content[0].text}</p>
        <NewsletterSubscribeForm />
      </section>

      {/* Connect */}
      <section className="py-6" style={{ borderBottom: '1px solid rgba(49,49,53,0.1)' }}>
        <SectionTitle text={getSectionTitle(socialIconsSection)} className="mb-5" />
        <SocialIcons />
      </section>

      {/* My Links */}
      <section className="pt-6 pb-8" style={{ borderBottom: '1px solid rgba(49,49,53,0.1)' }}>
        <SectionTitle text={getSectionTitle(linksSection)} className="mb-5" />
        {linksSection.items.map((link) => (
          <ButtonLink key={link.url.url} href={link.url.url} text={link.text} />
        ))}
      </section>

      {/* My Latest Video */}
      <section className="pt-6 pb-8" style={{ borderBottom: '1px solid rgba(49,49,53,0.1)' }}>
        <SectionTitle text={getSectionTitle(mostRecentVideoSection)} className="mb-5" />
        <ResponsiveIframe
          src={`https://www.youtube.com/embed/${data.allYoutubeVideo.edges[0].node.videoId}?feature=oembed`}
          title={data.allYoutubeVideo.edges[0].node.title}
        />
      </section>

      {/* Featured in */}
      <section className="py-6" style={{ borderBottom: '1px solid rgba(49,49,53,0.1)' }}>
        <SectionTitle text={getSectionTitle(pressSection)} />

        {/* Logo Links */}
        <FeaturedInLogosLinks />

        {/* Facebook Video */}
        <ResponsiveIframe
          className="mt-8 rounded"
          src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdespiertamerica%2Fvideos%2F612220232647699%2F&show_text=0&width=560"
          title="Andres Prieto Franklin"
        />

        {/* Tweet */}
        <div className="mt-10 mb-6">
          <Tweet tweetId={'1190398290636005376'} />
        </div>
      </section>
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query IndexQuery {
    allYoutubeVideo {
      edges {
        node {
          videoId
          title
        }
      }
    }
    allPrismic {
      nodes {
        data {
          body {
            items {
              text
              url {
                url
              }
            }
            primary {
              title {
                text
              }
              content {
                text
                type
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

export default IndexPage;

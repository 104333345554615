import React from 'react';
import PropTypes from 'prop-types';
import ProfileIcon from './icons/Profile';

export default function SectionTitle({ className, text }) {
  return (
    <h2 className={`flex items-center mb-3 ${className}`}>
      <ProfileIcon className="inline-block w-6" />
      <span className="inline-block ml-1 text-lg" style={{ fontWeight: 'bold' }}>
        {text}
      </span>
    </h2>
  );
}

SectionTitle.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

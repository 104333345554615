import React from 'react';
import PropTypes from 'prop-types';

export default function Facebook({ className, style }) {
  return (
    <svg
      className={className}
      style={style}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"></path>
    </svg>
  );
}

Facebook.defaultProps = {
  className: '',
  style: {},
};

Facebook.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

import React from 'react';
import Link from './icons/Link';
import PropTypes from 'prop-types';

export default function ButtonLink({ href, className, text }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`flex mt-2 items-center w-full px-4 py-3 text-gray-800 transition-all duration-300 ease-in-out bg-gray-200 rounded-lg hover:bg-gray-300 ${className}`}
    >
      <span style={{ maxWidth: '90%' }}>{text}</span>
      <Link className="inline-block ml-auto" style={{ fill: '#667EEA' }} />
    </a>
  );
}

ButtonLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

import React from 'react';
import claudinaryImage from '../utils/claudinaryImage';

export default function FeaturedInLogosLinks() {
  return (
    <>
      <div className="flex flex-wrap content-center justify-around">
        <div style={{ flexBasis: '45%' }} className="mt-3">
          <a
            href="https://www.facebook.com/watch/?v=612220232647699"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={claudinaryImage('/v1571779878/univision.svg')} alt="univision logo" />
          </a>
        </div>
        <div style={{ flexBasis: '45%' }} className="mt-3">
          <a
            href="https://polivision.modlangs.gatech.edu/10-rising-latinx-youtubers%E2%80%8B-expanding-the-conversation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="PolivisionLogo"
              src={claudinaryImage('/v1571782066/polivisionlogo.svg')}
              alt="polivision logo"
            />
          </a>
        </div>
        <div style={{ flexBasis: '45%' }} className="mt-3">
          <a
            href="https://youtube-creators.googleblog.com/2018/09/meet-creators-heading-to-three-special.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={claudinaryImage('/v1571779878/youtube_creators2.svg')}
              alt="youtube creators logo"
            />
          </a>
        </div>
        <div style={{ flexBasis: '45%' }} className="mt-3">
          <a
            href="https://www.gettyimages.com/fotos/andres-prieto-amfar?family=editorial&phrase=andres%20prieto%20amfar&sort=mostpopular#license"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={claudinaryImage('/v1571963858/getty.svg')} alt="getty logo" />
          </a>
        </div>
        <div style={{ flexBasis: '45%' }} className="mt-3">
          <a
            href="https://www.vogue.co.uk/news/article/edward-enninful-vogue-challenge-covers"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={claudinaryImage('/v1595374084/British-Vogue-Logo.jpg')}
              alt="british vogue logo"
              style={{ maxWidth: 110 }}
              className="mx-auto"
            />
          </a>
        </div>
        <div style={{ flexBasis: '45%' }} className="mt-3">
          <a
            href="https://www.larepublica.co/internet-economy/cinco-opciones-para-aprender-ingles-a-traves-de-videos-en-youtube-2905659"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={claudinaryImage('/v1597066411/LA_REPUBLICA.jpg')}
              alt="la republica logo"
              style={{ maxWidth: 140 }}
              className="mx-auto"
            />
          </a>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

export default function Link({ className, style }) {
  return (
    <svg
      className={className}
      style={style}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
      width="24"
      height="24"
    >
      <path
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-1a9 9 0 100-18 9 9 0 000 18zm4.12-13.12a3.007 3.007 0 010 4.253l-1.285 1.284-.709-.708 1.285-1.285a2.004 2.004 0 10-2.835-2.835L9.29 7.874l-.708-.709 1.284-1.284a3.007 3.007 0 014.252 0zm-4.696 7.53l1.285-1.284.709.709-1.285 1.284A2.999 2.999 0 018.007 15a3.007 3.007 0 01-2.126-5.133l1.284-1.285.71.71-1.285 1.284a2.006 2.006 0 001.417 3.422 1.99 1.99 0 001.417-.588zm1.708-5.25l.708.708-2.98 2.98-.708-.709 2.98-2.98z"
      ></path>
    </svg>
  );
}

Link.defaultProps = {
  className: '',
  style: {},
};

Link.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

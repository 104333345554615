import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import classnames from 'classnames';

export default function ResponsiveIframe({ className, src, title }) {
  const [loaded, setLoaded] = React.useState(false);

  const handleAfterLoad = () => setLoaded(true);
  return (
    <div
      className={classnames('relative h-0 overflow-hidden rounded', className)}
      style={{ paddingBottom: '56.25%', zIndex: 999 }}
    >
      <LazyLoadComponent afterLoad={handleAfterLoad} threshold={200}>
        <iframe
          className={classnames(
            'transition-opacity duration-700 ease-in-out delay-75',
            { 'opacity-100': loaded },
            { 'opacity-0': !loaded },
          )}
          allowFullScreen
          frameBorder="0"
          height="100%"
          src={src}
          title={title || ''}
          width="100%"
          style={{ left: 0, position: 'absolute', top: 0 }}
        />
      </LazyLoadComponent>
    </div>
  );
}

ResponsiveIframe.defaultProps = {
  title: '',
  className: '',
};

ResponsiveIframe.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
};

import React from 'react';
import Instagram from '../components/icons/Instagram';
import Youtube from '../components/icons/Youtube';
import Twitter from '../components/icons/Twitter';
import Facebook from '../components/icons/Facebook';
import TikTok from '../components/icons/TikTok';

export default function SocialIcons() {
  return (
    <div className="flex justify-between">
      <a
        href="https://www.instagram.com/andreseningles"
        target="_blank"
        rel="noopener noreferrer"
        title="instagram - https://www.instagram.com/andreseningles"
      >
        <Instagram className="w-6" style={{ fill: 'rgb(225, 48, 108)' }} />
      </a>
      <a
        href="https://www.youtube.com/user/soyimaginario"
        target="_blank"
        rel="noopener noreferrer"
        title="youtube - https://www.youtube.com/user/soyimaginario"
      >
        <Youtube className="w-6" style={{ fill: 'rgb(255, 0, 0)' }} />
      </a>
      <a
        href="https://www.tiktok.com/@andreseningles"
        target="_blank"
        rel="noopener noreferrer"
        title="tiktok - https://www.tiktok.com/@andreseningles"
      >
        <TikTok className="w-6" />
      </a>
      <a
        href="https://www.twitter.com/andreseningles_"
        target="_blank"
        rel="noopener noreferrer"
        title="twitter - https://www.twitter.com/andreseningles_"
      >
        <Twitter className="w-6" style={{ fill: 'rgb(85, 172, 238)' }} />
      </a>
      <a
        href="https://www.facebook.com/andreseningles"
        target="_blank"
        rel="noopener noreferrer"
        title="facebook - https://www.facebook.com/andreseningles"
      >
        <Facebook className="w-6" style={{ fill: 'rgb(59, 89, 152)' }} />
      </a>
    </div>
  );
}
